iframe {
  margin: 50px auto;
  width: calc(100% - 100px);
  max-width: 1000px;
  display: block;
  margin-top: 10px;
  /* height: 700px */
}

h2 {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
}

div.episode h3 {
  color: red;
}
